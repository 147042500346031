import '../css/Pagination.css'
import React, { useState, useEffect }from 'react';

function Pagination({ page = 0, itemsPerPage = 25, itemsCount = 20344, onPageChange = (page)=>{} }) {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 840);

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 840);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const totalPages = Math.ceil(itemsCount / itemsPerPage);
    const from = page * itemsPerPage + 1;
    const to = Math.min(from + itemsPerPage - 1, itemsCount);

    const getPaginationButtons = () => {
        const buttons = [];
        const maxButtons = isMobile ? 1 : 3;
        const halfMaxButtons = Math.floor(maxButtons / 2);

        let startPage = Math.max(1, page - halfMaxButtons + 1);
        let endPage = startPage + maxButtons - 1;

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(1, endPage - maxButtons + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <div
                    key={i}
                    className={`pagination-button ${i === page + 1 ? 'active' : ''}`}
                    onClick={() => onPageChange(i - 1)}
                >
                    {i}
                </div>
            );
        }

        if (!isMobile && startPage > 1) {
            buttons.unshift(<div key="start-dots" className="pagination-dots">...</div>);
            buttons.unshift(<div key="1" className="pagination-button" onClick={() => onPageChange(0)}>1</div>);
        }

        if (!isMobile && endPage < totalPages) {
            buttons.push(<div key="end-dots" className="pagination-dots">...</div>);
            buttons.push(<div key={totalPages} className="pagination-button" onClick={() => onPageChange(totalPages - 1)}>{totalPages}</div>);
        }

        return buttons;
    };

    return (
        <div className="pagination">
            <div className="pagination-container">
                {page != 0 && (<div className={`pagination-button nav prev`} disabled={page === 0} onClick={() => onPageChange(page - 1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className='icon'>
                        <path d="M3.931 10.468l4.074-4.4 4.074 4.4a.992.992 0 0 0 1.48 0c.41-.442.41-1.156 0-1.598L8.74 3.665a.992.992 0 0 0-1.48 0L2.44 8.87a1.194 1.194 0 0 0 0 1.598c.41.431 1.082.443 1.491 0z"></path>
                    </svg>
                </div>)}
                <div className="pagination-container-pages">
                    {getPaginationButtons()}
                </div>
                {page != totalPages - 1 && (<div className={`pagination-button nav next`} disabled={page >= totalPages - 1} onClick={() => onPageChange(page + 1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className='icon'>
                        <path d="M3.931 10.468l4.074-4.4 4.074 4.4a.992.992 0 0 0 1.48 0c.41-.442.41-1.156 0-1.598L8.74 3.665a.992.992 0 0 0-1.48 0L2.44 8.87a1.194 1.194 0 0 0 0 1.598c.41.431 1.082.443 1.491 0z"></path>
                    </svg>
                </div>)}
            </div>
            <div className="pagination-info">{from} - {to} from {itemsCount}</div>
        </div>
    );
}

export default Pagination;
