import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  Autocomplete
} from '@mui/material';
import '../css/Header.css';

const MemoizedDialog = React.memo(({
  open,
  handleClose,
  handleReset,
  minPrice,
  maxPrice,
  minArea,
  maxArea,
  errors,
  handleInputChange,
  isApplyDisabled,
  availableOptions,
}) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Select range</DialogTitle>
    <DialogContent>
      {['Price', 'Area'].map((label, index) => (
        <div key={label} style={{ marginBottom: '16px' }}>
          <div style={{ marginBottom: '8px' }}>{label}:</div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <TextField
              label="Min"
              value={index === 0 ? minPrice : minArea}
              onChange={handleInputChange(index === 0 ? 'minPrice' : 'minArea')}
              error={index === 0 ? errors.minPrice : errors.minArea}
              fullWidth
              variant="outlined"
              inputProps={{ inputMode: 'numeric', pattern: '\\d*' }}
              InputProps={{ endAdornment: <InputAdornment position="end">{index === 0 ? '€' : 'm²'}</InputAdornment> }}
            />
            <TextField
              label="Max"
              value={index === 0 ? maxPrice : maxArea}
              onChange={handleInputChange(index === 0 ? 'maxPrice' : 'maxArea')}
              error={index === 0 ? errors.maxPrice : errors.maxArea}
              fullWidth
              variant="outlined"
              inputProps={{ inputMode: 'numeric', pattern: '\\d*' }}
              InputProps={{ endAdornment: <InputAdornment position="end">{index === 0 ? '€' : 'm²'}</InputAdornment> }}
            />
          </div>
        </div>
      ))}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleReset} color="primary">Reset</Button>
      <Button onClick={handleClose} color="primary" disabled={isApplyDisabled}>
        {availableOptions} Options
      </Button>
    </DialogActions>
  </Dialog>
));

export default function Header({
  onFilterChange,
  onFilterApplied,
  initialFilters = {},
  initialOptionsCount = 0,
  cities = [],
  selectedCity = null,
  onSityChanged = (sity) => {}
}) {
  const [open, setOpen] = useState(false);
  const [minPrice, setMinPrice] = useState(initialFilters.minPrice || '');
  const [maxPrice, setMaxPrice] = useState(initialFilters.maxPrice || '');
  const [minArea, setMinArea] = useState(initialFilters.minArea || '');
  const [maxArea, setMaxArea] = useState(initialFilters.maxArea || '');
  const [errors, setErrors] = useState({
    minPrice: false,
    maxPrice: false,
    minArea: false,
    maxArea: false,
  });
  const [isApplyDisabled, setIsApplyDisabled] = useState(false);
  const [availableOptions, setAvailableOptions] = useState(initialOptionsCount);

  const prevFilters = useRef({ minPrice, maxPrice, minArea, maxArea });

  // Compute the number of active filters
  const activeFiltersCount = useMemo(() => {
    let count = 0;
    if (minPrice) count++;
    if (maxPrice) count++;
    if (minArea) count++;
    if (maxArea) count++;
    return count;
  }, [minPrice, maxPrice, minArea, maxArea]);

  useEffect(() => {
    setAvailableOptions(initialOptionsCount);
  }, [initialOptionsCount]);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    const appliedFilters = { minPrice, maxPrice, minArea, maxArea };
    if (onFilterApplied && JSON.stringify(appliedFilters) !== JSON.stringify(initialFilters)) onFilterApplied(appliedFilters);
    setOpen(false);
  };

  const handleReset = async () => {
    const appliedFilters = { minPrice, maxPrice, minArea, maxArea };
    const defaultFilters = {
      minPrice: '',
      maxPrice: '',
      minArea: '',
      maxArea: '',
    };
    if (JSON.stringify(appliedFilters) === JSON.stringify(defaultFilters)) {
      return;
    }

    setMinPrice('');
    setMaxPrice('');
    setMinArea('');
    setMaxArea('');
    setErrors({ minPrice: false, maxPrice: false, minArea: false, maxArea: false });
    setIsApplyDisabled(false);
    setAvailableOptions(initialOptionsCount);

    if (onFilterChange) {
      try {
        const optionsCount = await onFilterChange(defaultFilters);
        if (optionsCount) setAvailableOptions(optionsCount);
      } catch (error) {
        console.error('Error fetching options count:', error);
      }
    }
  };

  const validateFields = useCallback(async () => {
    const newFilters = { minPrice, maxPrice, minArea, maxArea };
    const filtersChanged = JSON.stringify(prevFilters.current) !== JSON.stringify(newFilters);

    if (!filtersChanged) return;

    const [minPriceNum, maxPriceNum, minAreaNum, maxAreaNum] = [
      Number(minPrice), Number(maxPrice), Number(minArea), Number(maxArea),
    ];

    const isMinPriceValid = !minPrice || (!isNaN(minPriceNum) && minPriceNum >= 0);
    const isMaxPriceValid = !maxPrice || (!isNaN(maxPriceNum) && maxPriceNum >= 0 && maxPriceNum >= minPriceNum);
    const isMinAreaValid = !minArea || (!isNaN(minAreaNum) && minAreaNum >= 0);
    const isMaxAreaValid = !maxArea || (!isNaN(maxAreaNum) && maxAreaNum >= 0 && maxAreaNum >= minAreaNum);

    setErrors({
      minPrice: !isMinPriceValid,
      maxPrice: !isMaxPriceValid,
      minArea: !isMinAreaValid,
      maxArea: !isMaxAreaValid,
    });

    const allValid = isMinPriceValid && isMaxPriceValid && isMinAreaValid && isMaxAreaValid;
    setIsApplyDisabled(!allValid);

    if (allValid && onFilterChange) {
      try {
        const optionsCount = await onFilterChange(newFilters);
        setAvailableOptions(optionsCount);
        prevFilters.current = newFilters;
      } catch (error) {
        console.error('Error fetching options count:', error);
      }
    }
  }, [minPrice, maxPrice, minArea, maxArea, onFilterChange]);

  useEffect(() => {
    validateFields();
  }, [validateFields]);

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      switch (field) {
        case 'minPrice': setMinPrice(value); break;
        case 'maxPrice': setMaxPrice(value); break;
        case 'minArea': setMinArea(value); break;
        case 'maxArea': setMaxArea(value); break;
        default: break;
      }
    }
  };

  return (
    <AppBar position="static">
      <Toolbar className="toolbar">
        <img src={"./logo64.png"} alt="Flatoria Logo" style={{ height: '50px', width: '50px', marginRight: '16px' }} />
        <Autocomplete
          disablePortal
          options={cities}
          getOptionLabel={(option) => option.toString()}
          value={selectedCity} // Устанавливаем значение выбранного города
          onChange={(event, value) => {
            onSityChanged(value);
          }}
          sx={{
            width: 300,
            marginRight: '16px',
            '& .MuiInputBase-root': { 
              height: 50,
              color: 'white',
              backgroundColor: 'transparent',
              borderColor: 'white',
              '& fieldset': {
                borderColor: 'white',
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '& .MuiAutocomplete-popupIndicator': {
              color: 'white',
            },
            '& .MuiAutocomplete-clearIndicator': {
              color: 'white',
            },
            '& .MuiAutocomplete-option': {
              backgroundColor: 'black',
              color: 'white',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="City"
              InputLabelProps={{
                style: { color: 'white' },
              }}
            />
          )}
        />
        <Button
          color="inherit"
          variant="outlined"
          sx={{ borderColor: 'white', color: 'white' }}
          onClick={handleClickOpen}
        >
          Filter {activeFiltersCount > 0 && `(${activeFiltersCount})`}
        </Button>

        <MemoizedDialog
          open={open}
          handleClose={handleClose}
          handleReset={handleReset}
          minPrice={minPrice}
          maxPrice={maxPrice}
          minArea={minArea}
          maxArea={maxArea}
          errors={errors}
          handleInputChange={handleInputChange}
          isApplyDisabled={isApplyDisabled}
          availableOptions={availableOptions}
        />
      </Toolbar>
    </AppBar>
  );
}
