import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, useMap, useMapEvent } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../css/MapComponent.css';

const homeIcon = new L.Icon({
  iconUrl: '/images/home.svg',
  iconSize: [16, 16],
  iconAnchor: [8, 8],
  popupAnchor: [0, -32],
});

const specialIcon = new L.Icon({
  iconUrl: '/images/location_pin.svg',
  iconSize: [38, 38],
  iconAnchor: [19, 38],
});

const MapComponent = ({
  locations = [],
  center = [],
  zoom = 14,
  onBoundsChange = (bounds) => {},
  specialMarkerPosition = null,
  onMarkerClick = (location) => {},
}) => {

  const lastBoundsRef = useRef(null);

  const updateVisibleMarkers = (map) => {
    if (!map) {
      return;
    }

    const bounds = map.getBounds();
    const topLeft = bounds.getNorthWest();
    const bottomRight = bounds.getSouthEast();
    
    if (!lastBoundsRef.current || !bounds.equals(lastBoundsRef.current)) {
      lastBoundsRef.current = bounds;      
      onBoundsChange({
        topLeftLat: topLeft.lat,
        topLeftLng: topLeft.lng,
        bottomRightLat: bottomRight.lat,
        bottomRightLng: bottomRight.lng
      });
    }
  };

  const MapCenterUpdater = ({ center }) => {
    const map = useMap();
  
    useEffect(() => {
      if (center && center.length === 2) {
        map.setView(center, zoom);
      }
    }, [center, map]);
  
    return null;
  };
  
  const MapEvents = () => {
    const map = useMapEvent('moveend', () => {
      updateVisibleMarkers(map);
    });

    useEffect(() => {
      if (map) {
        updateVisibleMarkers(map);
      }
    }, [map]);

    return null;
  };

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      minZoom={9}
      className="leaflet-container"
    >
      {/* https://api.maptiler.com/maps/bright-v2/?key=hZrWdLOfbRU8zRL0AcVs#{s}/{x}{y} */}
      <TileLayer
        url="https://api.maptiler.com/maps/bright-v2/256/{z}/{x}/{y}.png?key=hZrWdLOfbRU8zRL0AcVs"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      <MapCenterUpdater center={center} />
      <MapEvents />

      {locations.map((location, locationIndx) => (
        <Marker
          key={locationIndx}
          position={[location[0], location[1]]}
          icon={homeIcon}
          eventHandlers={{
            click: () => onMarkerClick(location),
          }}
        />
      ))}

      {specialMarkerPosition && (
        <Marker position={specialMarkerPosition} icon={specialIcon} />
      )}
    </MapContainer>
  );
};

export default MapComponent;
