import React from 'react';
import '../css/RealtyCard.css';

function RealtyCard({ apartment, onCardHover = () => {},  onCardLeave = () => {}}) {
  return (
    <a href={apartment.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
      <div className="realty-card" onMouseEnter={onCardHover} onMouseLeave={onCardLeave}>
        <img className="realty-image" src={apartment.image_url} alt={apartment.title} />

        <div className="realty-info">
          <div className="realty-header">
            <div className="realty-price">€ {apartment.price}</div>
            <div className="property-item">
              <svg width="20" height="20" viewBox="0 0 20 20"><path d="M2.50012 4.16667V7.5h1.66667V4.16667h3.33333V2.5H4.16679c-.91667 0-1.66667.75-1.66667 1.66667ZM4.16679 12.5H2.50012v3.3333c0 .9167.75 1.6667 1.66667 1.6667h3.33333v-1.6667H4.16679V12.5Zm11.66671 3.3333h-3.3334V17.5h3.3334c.9166 0 1.6666-.75 1.6666-1.6667V12.5h-1.6666v3.3333Zm0-13.3333h-3.3334v1.66667h3.3334V7.5h1.6666V4.16667c0-.91667-.75-1.66667-1.6666-1.66667Z"></path></svg>
              <span>{apartment.area} м²</span>
            </div>
          </div>

          <h3 className="realty-title">{apartment.title}</h3>

          <div className="realty-subtitles">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium mui-155rr8j" focusable="false" aria-hidden="true" width="16" height="16">
              <path fillRule="evenodd" d="M12 2a8 8 0 0 1 7.89 9.327q-.761 5.583-6.578 9.855c-.852.627-1.423.657-2.555.035q-5.605-3.99-6.57-9.487A8 8 0 0 1 12 2m0 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6" clipRule="evenodd"></path>
            </svg>
            <p>{apartment.location}</p> 
          </div>
        </div>
      </div>
    </a>
  );
};

export default RealtyCard;
