import React from 'react';
import RealtyCard from './RealtyCard';

function ApartmentList({apartments, onApartmentHover = (location) => {},  onApartmentLeave = () => {}}) {
  return (
    <>
      {apartments.map((location) => (
        location[2].map((apartment) => (
          <RealtyCard 
            key={apartment.url} 
            apartment={{
              ...apartment,
              latitude: location[0],
              longitude: location[1]
            }} 
            onCardHover={() => {
              onApartmentHover(location)
            }}
            onCardLeave={onApartmentLeave}
          />
        ))
      ))}
    </>
  );
}

export default ApartmentList;
