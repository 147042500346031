import React, { useState } from 'react';
import MapComponent from './MapComponent';
import ApartmentList from './ApartmentList';
import '../css/App.css';
import mapController from './MapController';
import Pagination from './Pagination';
import Header from './Header';
import cities from './cities.json'

let apartmentsPerPage = 35;

const cittiesMap = cities.reduce((acc, city) => {
  acc[city.name] = [city.latitude, city.longitude];
  return acc;
}, {});

function App() {
  const [apartments, setApartments] = useState([]);
  const [points, setPoints] = useState([]);
  const [page, setPage] = useState(0);
  const [apartmentsCount, setApartmentsCount] = useState(0);
  const [hoveredLocaton, setHoveredLocaton] = useState(null);
  const [locatonName, setLocatonName] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [cityInFocusName, setCityInFocusName] = useState("Bratislava")
  const [cityInFocus, setCityInFocus] = useState(cittiesMap.Bratislava)
  const updateApartments = async (nextPage, location) =>
  {
    setApartments([]);
    if(!location)
    {
      setLocatonName(null);
    }

    const fetchedApartments = await mapController.FetchApartments(nextPage * apartmentsPerPage, apartmentsPerPage, location);
    if(fetchedApartments)
    {
      setLocatonName(location && fetchedApartments[0][2][0].location.toUpperCase());
      setApartments(fetchedApartments);
    }
  }

  const updatePoints = async () =>
  {
    const fetchedPoints = await mapController.FetchVisiblePoints();
    if(fetchedPoints)
    {
      setPoints(fetchedPoints);
    }
  }

  const updateApartmentsCount = async (location) =>
  {
    const fetchedApartmentsCount = await mapController.FetchApartmentsCount(location);
    if(fetchedApartmentsCount)
    {
      setApartmentsCount(fetchedApartmentsCount.count);
    }
  }

  const updatePage = async (page, location) => {
    setPage(page);
    await updateApartments(page, location);
  };

  const updateSelecetedLocation = async (location) =>
  { 
    setSelectedLocation(location);   
    await Promise.all([
      updateApartmentsCount(location),
      updatePage(0, location)
    ]);
  }

  const refreshData = async () => {
    await Promise.all([
      updatePoints(),
      updateApartmentsCount(selectedLocation),
      updatePage(0, selectedLocation)
    ]);
  };

  const onBoundsChange = async (bounds) => {
    setCityInFocus(null);
    setCityInFocusName(null);
    mapController.setBounds(bounds);
    await refreshData();
  };

  const onFilterChange = async (filter)  => {
    const apartmentsCountData = await mapController.FetchApartmentsCount(selectedLocation, filter);
    return apartmentsCountData ? apartmentsCountData.count : null;
  };

  const onFilterApplied = async (filter)  => {
    mapController.SetFilter(filter);
    await refreshData();
  }

  const updateCityInFocus = (city) =>{
    if (city in cittiesMap)
    {
      setCityInFocus(cittiesMap[city]);
    }
  }

  return (
    <>
    <Header initialOptionsCount={apartmentsCount} initialFilters={mapController.filter} onFilterChange={onFilterChange} onFilterApplied={onFilterApplied} cities={cities.map(city => city.name)} onSityChanged={updateCityInFocus} selectedCity={cityInFocusName}/>
    <div className="app-container">
      <div className="content-container">
        <div className="side-panel">
          {locatonName && (
          <div className='back-all-apartments-container'>
            <div role="button" onClick={() => updateSelecetedLocation(null)} className='back-all-apartments-button'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className='icon'>
                <path d="M3.931 10.468l4.074-4.4 4.074 4.4a.992.992 0 0 0 1.48 0c.41-.442.41-1.156 0-1.598L8.74 3.665a.992.992 0 0 0-1.48 0L2.44 8.87a1.194 1.194 0 0 0 0 1.598c.41.431 1.082.443 1.491 0z"></path>
              </svg>
            </div>
            <div className='back-all-apartments-text'>{locatonName}</div>
          </div>)  
          }
          <div className="apartment-list">
            <ApartmentList
              apartments={apartments}
              onApartmentHover={setHoveredLocaton}
              onApartmentLeave={() => setHoveredLocaton(null)}
            />
          </div>
          {apartmentsCount > apartmentsPerPage && (
            <div>
              <Pagination
                page={page}
                itemsPerPage={apartmentsPerPage}
                itemsCount={apartmentsCount}
                onPageChange={(page) => updatePage(page, selectedLocation)}
              />
            </div>
          )}
        </div>
        <div className="map-container">
          <MapComponent
            center={cityInFocus}
            locations={points}
            onBoundsChange={onBoundsChange}
            specialMarkerPosition={hoveredLocaton || selectedLocation}
            onMarkerClick={updateSelecetedLocation}
          />
        </div>
      </div>
    </div>
    </>
  );
}

export default App;
