const Path = {
    localhost:'http://localhost:4000',
    development: 'http://167.71.171.81',
    production: ''
}

class ServerApi
{
    constructor() {
        this.host = Path.production;
        this.controllers = {};  
        this.idCounts = 0;
    }

    log(message)
    {
        console.log(message)
    }
    
    error(message)
    {
        console.error(message)
    }

    async fetch(request, params) {
        let query = `${this.host}/api/${request}`;
        const queryParams = new URLSearchParams(params).toString();
        if (queryParams) {
            query += `?${queryParams}`;
        }
        
        if (this.controllers[request]) {
            this.controllers[request].controller.abort();
            delete this.controllers[request]; 
        }
        
        const controller = new AbortController();
        const queryId = ++this.idCounts;

        this.controllers[request] = {
            controller: controller,
            id: queryId
        }

        this.log(`-> Request[${queryId}]: ${request}`)
    
        let data = null;
        try {
            const response = await fetch(query, { signal: controller.signal });
            if (response.ok) {
                data = await response.json();
                this.log(`<- Response[${queryId}]: ${request}`);
                if(!this.controllers[request])
                {
                    this.error(`wtf!? [${queryId}]`)
                }
            } else {
                this.error(`Error while requesting: ${response.status} ${response.statusText}`);
            }
        } catch (error) {
            if (error.name === 'AbortError') {
                this.log(`x Abort request[${queryId}]: ${request}`);
            } else {
                this.error(`Error while requesting: ${error}`);
            }
        } finally {
            if(this.controllers[request] && this.controllers[request].id == queryId)
            {
                delete this.controllers[request]; 
            }
        }
    
        return data;
    }
}

class MapController {
    constructor() {
        this.filter = {
            minPrice: '',
            maxPrice: '',
            minArea: '',
            maxArea: ''
        }

        this.bounds = null
        
        this.api = new ServerApi();
    }

    async FetchVisiblePoints()
    {
        if(!this.bounds) return null;

        const tLLat_raw = this.bounds.topLeftLat;
        const tLLng_raw = this.bounds.topLeftLng;
        const bRLat_raw = this.bounds.bottomRightLat;
        const bRLng_raw = this.bounds.bottomRightLng;

        // const tLLat_raw = 99999;
        // const tLLng_raw = -99999;
        // const bRLat_raw = -99999;
        // const bRLng_raw = 99999;

        const persent = 0.5;
        const latDiff = Math.abs(tLLat_raw - bRLat_raw) * persent;
        const lngDiff = Math.abs(tLLng_raw - bRLng_raw) * persent;
        
        const tLLat = tLLat_raw + latDiff;
        const tLLng = tLLng_raw - lngDiff;
        const bRLat = bRLat_raw - latDiff;
        const bRLng = bRLng_raw + lngDiff;

        return await this.api.fetch("pointsinsquare", {
            topLeftLat: tLLat,
            topLeftLng: tLLng,
            bottomRightLat: bRLat,
            bottomRightLng: bRLng,
            priceRangeMin: this.filter.minPrice,
            priceRangeMax: this.filter.maxPrice,
            areaRangeMin: this.filter.minArea,
            areaRangeMax: this.filter.maxArea
        });
    }

    async FetchApartmentsCount(locaton = null, filter = null)
    {
        if(!this.bounds && !locaton) return null;

        const bouds = locaton ? {
            topLeftLat: locaton[0],
            topLeftLng: locaton[1],
            bottomRightLat: locaton[0],
            bottomRightLng: locaton[1]
        } : this.bounds;

        if(!filter)
        {
            filter = this.filter
        }

        return await this.api.fetch("apartmentscount", {
            topLeftLat: bouds.topLeftLat,
            topLeftLng: bouds.topLeftLng,
            bottomRightLat: bouds.bottomRightLat,
            bottomRightLng: bouds.bottomRightLng,
            priceRangeMin: filter.minPrice,
            priceRangeMax: filter.maxPrice,
            areaRangeMin: filter.minArea,
            areaRangeMax: filter.maxArea
        });
    }

    async FetchApartments(from, count, locaton = null)
    {
        if(!this.bounds && !locaton) return null;

        const bouds = locaton ? {
            topLeftLat: locaton[0],
            topLeftLng: locaton[1],
            bottomRightLat: locaton[0],
            bottomRightLng: locaton[1]
        } : this.bounds;

        return await this.api.fetch("apartmentsinsquare", {
            topLeftLat: bouds.topLeftLat,
            topLeftLng: bouds.topLeftLng,
            bottomRightLat: bouds.bottomRightLat,
            bottomRightLng: bouds.bottomRightLng,
            priceRangeMin: this.filter.minPrice,
            priceRangeMax: this.filter.maxPrice,
            areaRangeMin: this.filter.minArea,
            areaRangeMax: this.filter.maxArea,
            from: from,
            count: count
        });
    }

    setBounds(bounds)
    {
        this.bounds = bounds;
    }

    SetFilter(filter)
    {
        this.filter = filter;
    }
}
  
const mapController = new MapController();
export default mapController;
